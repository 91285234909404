<!--
 * @Version: 2.0
 * @Author: chengweijia-tel:18702108979
 * @Date: 2022-12-15 14:27:17
 * @LastEditTime: 2023-07-11 16:34:55
 * @Description: 
-->
<template>
  <div class="s-banner" :style="{ height: height * num + 100 + 'px' }">
    <el-carousel
      v-if="swiperList && swiperList.length > 0"
      ref="imgCarouselRef"
      :height="height * num + 100 + 'px'"
      direction="vertical"
      indicator-position="none"
      :interval="5000"
      :autoplay="false"
    >
      <el-carousel-item :name="item.id + ''" v-for="(item, index) in swiperList" :key="item">
        <div
          @click="() => handlePlay(item, index)"
          class="s-banner__img"
          :style="{
            'background-image': `url(${
              item.photoUrl && item.photoUrl.includes('.mp4') ? item.mobilePhotoUrl : item.photoUrl
            })`
          }"
        >
          <div class="mainPageArea">
            <div class="mainPage">
              <p class="name" v-if="item.titleDisplay">{{ item.name }}</p>
              <!-- <p class="remark">{{ item.remark }}</p> -->
              <pre
                v-if="item.remark && !item.remark.includes('nbsp') && item.contentDisplay"
                class="remark"
                v-html="item.remark"
              ></pre>
              <div
                class="play"
                @click="() => handlePlay(item, index)"
                v-if="item.photoUrl && item.photoUrl.includes('.mp4')"
              >
                <caret-right style="width: 1.5em; height: 1.5em" />
                <p>立即播放</p>
              </div>
            </div>
          </div>
        </div>
        <!-- <video v-else class="s-banner__img" :src="item.photoUrl"></video> -->
      </el-carousel-item>
    </el-carousel>
    <div
      class="nameArea"
      :style="{
        height: height * num + 100 + 'px'
      }"
      ref="nameAreaRef"
    >
      <ul
        :class="[
          'nameListArea',
          {
            alignCenter: num > swiperList.length
          }
        ]"
        :style="{
          height: height * num + 'px'
        }"
        ref="nameListRef"
        v-if="swiperList && swiperList.length > 1"
      >
        <li
          :class="['name-item', { active: curIndex === item.index }]"
          :style="{
            height: height + 'px'
          }"
          @mouseenter="() => handleMouseEnter(item, index)"
          @mouseleave="() => handleMouseLeave(item, index)"
          v-for="(item, index) in nameList"
          :key="index"
        >
          <div>
            <p class="name">{{ item.name }}</p>
            <!-- <p v-if="curIndex === item.index && !item.remark.includes('nbsp')" class="remark">{{ item.remark }}</p> -->
            <pre
              v-if="curIndex === item.index && item.remark && !item.remark.includes('nbsp')"
              class="remark"
              v-html="item.remark"
            ></pre>
          </div>
          <img @click="() => handlePlay(item, index)" class="img" :src="item.img" />
        </li>
      </ul>
    </div>
    <el-dialog v-model="dialogVideoVisible" @close="handleCloseDialog" :append-to-body="true">
      <video ref="videoRef" :src="swiperList[curIndex].photoUrl" controls></video>
    </el-dialog>
  </div>
</template>

<script setup>
import { onMounted, getCurrentInstance, ref, defineProps, nextTick, onBeforeUnmount } from 'vue'
import { CaretRight } from '@element-plus/icons'
import { useRoute, useRouter } from 'vue-router'
import 'swiper/swiper-bundle.min.css'
import store from '@/store'

// route
const route = useRoute()
const router = useRouter()

// this
const { proxy } = getCurrentInstance()

// props
const props = defineProps({
  activeType: String || Number,
  type: String,
  num: Number,
  height: Number
})

const curIndex = ref(0)
const swiperList = ref([])
const nameList = ref([])
const recruitType = route.meta.recruitType
// const currentRouteName = ref(router.currentRoute.value.name)
const imgCarouselRef = ref(null)
const nameAreaRef = ref(null)
const nameListRef = ref(null)
const videoRef = ref(null)
let timer = null
let dialogVideoVisible = ref(false)
const duration = 5000
let scrollTop, endScrollTop
let scrollTimer = null

/**
 * 获取banner
 *
 */
const getSwiperList = () => {
  const list = ['social', 'CampusPosition', 'blue']
  const params = {
    type: '0',
    recruitingType: recruitType,
    pageNum: 1,
    pageSize: 10,
    status: 1
  }
  proxy.$http.campus.getPCActivityByPage(params).then((res) => {
    // console.log('newLIst', swiperList.value, nameList.value)
    if (nameList.value && nameList.value.length > 0) {
      return
    }
    swiperList.value = res.list
    res.list.forEach((el, index) => {
      // console.log(el)
      nameList.value.push({
        name: el.name,
        remark: el.remark,
        id: el.id + '',
        index,
        isVideo: el.photoUrl && el.photoUrl.includes('.mp4'),
        img: el.photoUrl && el.photoUrl.includes('.mp4') ? el.mobilePhotoUrl : el.photoUrl
      })
    })
    // console.log('newSiwList', swiperList.value, nameList.value)
    nextTick(() => {
      autoPlay(duration)
      if (swiperList.value.length > props.num) {
        nameListRef.value.addEventListener('scroll', handleScoll)
      }
    })
  })
}

const handleScoll = (item) => {
  clearTimeout(scrollTimer)
  scrollTimer = setTimeout(isScrollEnd, 300)
  scrollTop = nameListRef.value.scrollTop
  // nameListRef.value.scrollTop = Math.floor(nameListRef.value.scrollTop / props.height) * props.height
}

const isScrollEnd = () => {
  endScrollTop = nameListRef.value.scrollTop
  if (scrollTop === endScrollTop) {
    nameListRef.value.scrollTop = Math.round(nameListRef.value.scrollTop / props.height) * props.height
  }
}

//nameList的autoPlay
const autoPlay = (time = duration) => {
  stopAutoPlay()
  // const padding = parseInt(window.getComputedStyle(nameAreaRef.value, null)['padding-top']) * 2
  const padding = 100
  if (swiperList.value.length > 1) {
    timer = setInterval(() => {
      curIndex.value = (curIndex.value + 1) % swiperList.value.length
      imgCarouselRef.value.setActiveItem(nameList.value[curIndex.value].id)
      if (curIndex.value % props.num === 0 && curIndex.value !== 0) {
        nameListRef.value.scrollTop = (nameAreaRef.value.offsetHeight - padding) * (curIndex.value / props.num)
      }
      if (curIndex.value === 0) {
        nameListRef.value.scrollTop = 0
      }
    }, time)
  }
}

const stopAutoPlay = () => {
  if (timer) {
    clearInterval(timer)
    timer = null
  }
}

//点击右侧名字
const handleMouseEnter = (item, index) => {
  stopAutoPlay()
  imgCarouselRef.value.setActiveItem(item.id)
  curIndex.value = index
}

//点击右侧名字
const handleMouseLeave = (item, index) => {
  // 显示dialog的时候会触发mouseLeave事件
  if (!dialogVideoVisible.value) {
    nextTick(() => {
      autoPlay()
    })
  }
}

const handlePlay = (item, index) => {
  if ((item.photoUrl && item.photoUrl.includes('.mp4')) || item.isVideo) {
    stopAutoPlay()
    dialogVideoVisible.value = true
    curIndex.value = index
    nextTick(() => {
      videoRef.value.play()
    })
  }
}

const handleCloseDialog = () => {
  // videoRef.value.pause()
  // console.log('videoRef', videoRef.value.currentTime, typeof videoRef.value.currentTime)
  if (videoRef.value.currentTime !== 0) {
    videoRef.value.pause()
    videoRef.value.currentTime = 0
  }
  autoPlay()
}

onMounted(() => {
  // console.log('载入了')
  if (route.query.ids && route.query.active) {
    getActivityStorage()
  } else {
    getSwiperList()
  }
})

const getActivityStorage = () => {
  const params = {
    key: route.query.ids
  }
  proxy.$http.campus.getActivityStorage(params).then((res) => {
    if (res) {
      if (nameList.value && nameList.value.length > 0) {
        nameList.value = []
      }
      // console.log('res', JSON.parse(res))
      swiperList.value = [JSON.parse(res)]
      swiperList.value.forEach((el, index) => {
        nameList.value.push({
          name: el.name,
          remark: el.remark,
          id: el.id + '',
          index,
          isVideo: el.photoUrl && el.photoUrl.includes('.mp4'),
          img: el.photoUrl && el.photoUrl.includes('.mp4') ? el.mobilePhotoUrl : el.photoUrl
        })
      })
      // console.log(swiperList.value, nameList.value)
    } else {
      getSwiperList()
    }
  })
}

onBeforeUnmount(() => {
  // console.log('销毁了')
  stopAutoPlay()
})
</script>

<style lang="scss">
.alignCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.s-banner {
  width: 100%;
  // height: 400px;
  position: relative;
  font-family: PingFangSC-Medium, PingFang SC;
  user-select: none;
  .s-banner__img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    margin: 0 auto;
    position: relative;
    cursor: pointer;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    .mainPageArea {
      width: 500px;
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0px;
      // background: linear-gradient(270deg, rgba(24, 33, 50, 0) 0%, rgba(31, 38, 50, 0.8) 100%);
      background: linear-gradient(270deg, rgba(24, 33, 50, 0) 0%, rgba(31, 38, 50, 0.8) 100%);
      .mainPage {
        position: absolute;
        left: 67px;
        bottom: 56px;
        .name {
          font-size: 36px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          height: 50px;
          line-height: 50px;
          margin-bottom: 20px;
        }
        .remark {
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 16px;
          width: 400px;
          height: 28px;
          line-height: 28px;
          overflow: hidden;
          p {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        .play {
          margin-top: 30px;
          width: 160px;
          height: 56px;
          background: rgba(255, 255, 255, 0.3);
          border-radius: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          font-weight: 500;
          color: #ffffff;
          line-height: 20px;
          .icon {
            font-size: 12px;
          }
          &:hover {
            background: #a0cfff;
          }
        }
      }
    }
  }
  .nameArea {
    position: absolute;
    width: 500px;
    // height: 100%;
    background: linear-gradient(90deg, rgba(24, 33, 50, 0) 0%, rgba(31, 38, 50, 0.8) 100%);
    right: 0;
    top: 0px;
    color: #fff;
    // padding: 20px 10px;
    box-sizing: border-box;
    // -webkit-mask: linear-gradient(transparent, red 10% 90%, transparent);
    z-index: 9;
    cursor: pointer;
    scroll-behavior: smooth;
    transition: all 1s ease-in-out;
    .nameListArea {
      width: 100%;
      height: 360px;
      margin-top: 50px;
      position: absolute;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0;
      }
      .name-item {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        // height: 90px;
        padding-right: 30px;
        box-sizing: border-box;
        div {
          width: calc(100% - 110px);
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          p {
            width: 100%;
            white-space: nowrap;
            text-align: right;
            font-size: 14px;
            padding-left: 10px;
            padding-right: 10px;
            box-sizing: border-box;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .remark {
            height: 28px;
            line-height: 28px;
            overflow: hidden;
            font-size: 14px;
          }
        }
        img {
          width: 100px;
          height: 50px;
          background: rgba(31, 38, 50, 0.7);
          border-radius: 8px;
        }
        &:hover,
        &.active {
          font-size: 14px;
          .name {
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 28px;
            font-size: 20px;
          }
          img {
            border: 4px solid #3693ff;
          }
        }
      }
    }
  }
}
</style>
