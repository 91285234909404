
import { defineComponent, reactive, toRefs, ref, getCurrentInstance, ComponentInternalInstance, watch } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Loading } from '@element-plus/icons'
import ZEmpty from '@/components/layout/z-empty.vue'

interface stateOption {
  positionList: any
  type: string
}

export default defineComponent({
  components: {
    ZEmpty,
    Loading
  },
  props: ['type', 'list', 'hasNextPage'],
  setup(props, { emit }) {
    const { proxy }: { proxy: any } = getCurrentInstance() as ComponentInternalInstance
    const { type, list } = toRefs(props)
    const router = useRouter()
    const route = useRoute()
    const store = useStore()

    const currentRouteName = ref(router.currentRoute.value.name)

    const state = reactive({
      positionList: list.value,
      type: type.value
    }) as stateOption

    // console.log(`list`, list)
    // console.log(`state.positionList`, state.positionList)

    watch([list], (newValue, oldValue) => {
      state.positionList = list.value
    })

    const handleJobClick = (item: any) => {
      router.push({
        name: 'PositionDetail',
        query: {
          id: item.id || item.postId
        }
      })
    }

    // 收藏职位
    const cancelOrCollectPost = (item: any, operationType: string, index: number) => {
      if (!localStorage.getItem('Authorization')) {
        ElMessageBox.confirm('登录用户才能收藏', 'Warning', {
          confirmButtonText: '去登录',
          cancelButtonText: '暂不登录',
          type: 'info',
          title: ''
        }).then(() => {
          store.dispatch('setRedirectUrl', route.fullPath)
          router.push({
            name: 'Login'
          })
        })
        return false
      }
      proxy.$http.position.cancelOrCollectPost({ id: item.id, operationType }).then((res: any) => {
        item.isCollect = !item.isCollect
        if (state.type == 'myLike' && operationType == `cancel`) {
          state.positionList.splice(index, 1)
        }
      })
    }

    const toPosition = () => {
      router.push({
        name: 'social'
      })
    }

    return {
      ...toRefs(state),
      handleJobClick,
      cancelOrCollectPost,
      toPosition,
      currentRouteName
    }
  }
})
