
import {
  defineComponent,
  reactive,
  toRefs,
  getCurrentInstance,
  ComponentInternalInstance,
  onMounted,
  watch,
  computed,
  ref
} from 'vue'
import { cities } from './constant'
import { useRoute, useRouter } from 'vue-router'
import { CaretBottom, CaretRight } from '@element-plus/icons-vue'

interface stateOption {
  workPlaceArr: any
  cities: any
  typeList: any
  wIds: any
  pIds: any
  fixed: boolean
  workHide: boolean
  positionHide: boolean
  schoolObjectHide: boolean
  schoolObject: any
}

export default defineComponent({
  components: {
    // ZSearch,
    // ZQrcode
    CaretBottom
    // CaretRight
  },
  props: ['recruitType', 'positionTypeIds', 'workPlaceIds', 'schoolObjects'],
  setup(props, { emit }) {
    const { proxy }: { proxy: any } = getCurrentInstance() as ComponentInternalInstance
    const { recruitType, positionTypeIds, workPlaceIds, schoolObjects } = toRefs(props)

    const router = useRouter()
    const route = useRoute()
    const state = reactive({
      workPlaceArr: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      positionProps: {
        children: 'list',
        label: 'value'
      },
      // 工作地点
      cities,
      // 职位类别
      typeList: [],
      wIds: [],
      pIds: [],
      positionList: [],
      fixed: false,
      workHide: false,
      positionHide: false,
      // 招聘对象
      schoolObjectHide: false,
      schoolObject: []
    }) as stateOption

    const workTreeRef = ref(null)
    const positionRef = ref(null)

    const getTypeList = () => {
      proxy.$http.position
        .getDictionaryCascade({ groupName: 'post_type_name', type: String(recruitType.value) })
        .then((res: any) => {
          state.typeList = res
        })
    }

    const getWorkPlace = () => {
      proxy.$http.position.getWorkPlace({ type: recruitType.value }).then((res: any) => {
        state.workPlaceArr = res.children
      })
    }

    const handleCheck = (curValue, valueObj, type) => {
      const checkedKeys = valueObj.checkedKeys
      if (type === 'workPlace') {
        state.wIds = checkedKeys
      } else if (type === 'position') {
        state.pIds = checkedKeys
      }
      handleFilterChange()
    }

    const handleHide = (type) => {
      if (type === 'workPlace') {
        state.workHide = !state.workHide
      } else if (type === 'position') {
        state.positionHide = !state.positionHide
      } else if (type === 'schoolObject') {
        state.schoolObjectHide = !state.schoolObjectHide
      }
    }
    const handleEmpty = (type) => {
      if (type === 'workPlace') {
        workTreeRef.value.setCheckedKeys([])
        state.wIds = []
      } else if (type === 'position') {
        positionRef.value.setCheckedKeys([])
        state.pIds = []
      } else if (type === 'schoolObject') {
        state.schoolObject = []
      }
      handleFilterChange()
    }

    onMounted(() => {
      getTypeList()
      getWorkPlace()
    })

    watch([positionTypeIds, workPlaceIds, schoolObjects], (nV) => {
      if (nV[0]) {
        state.pIds = nV[0]
        positionRef.value.setCheckedKeys(nV[0])
      } else {
        state.pIds = []
        positionRef.value.setCheckedKeys([])
      }

      if (nV[1]) {
        state.wIds = nV[1]
        workTreeRef.value.setCheckedKeys(nV[1])
      } else {
        state.wIds = []
        workTreeRef.value.setCheckedKeys([])
      }

      if (nV[2]) {
        state.schoolObject = nV[2]
      } else {
        state.schoolObject = []
      }
    })

    const handleFilterChange = () => {
      emit('filterChange', state.pIds, state.wIds, state.schoolObject)
    }

    const handleSchoolObjectChange = () => {
      emit('filterChange', state.pIds, state.wIds, state.schoolObject)
    }

    return {
      ...toRefs(state),
      workTreeRef,
      positionRef,
      handleFilterChange,
      handleCheck,
      handleHide,
      handleEmpty,
      handleSchoolObjectChange
    }
  }
})
