
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  getCurrentInstance,
  ComponentInternalInstance,
  onActivated,
  onMounted,
  nextTick
} from 'vue'
// import ZPositionFilter from '@/views/position-list/components/z-position-filter.vue'
import ZPositionClone from '@/views/position-list/components/z-position-clone.vue'
import ZPositionList from '@/views/position-list/components/z-position-list.vue'
import ZQrcode from '@/components/layout/z-qrcode.vue'
import ZSearch from '@/components/layout/z-search.vue'
import PcBanner from '@/components/banner/banner-pc.vue'
import { useRoute, onBeforeRouteLeave } from 'vue-router'

interface stateOption {
  routeName: string
  type: string
  list: any[]
  hasNextPage: boolean
  pageNum: number
  search: any
  positionTypeIds: any
  workPlaceIds: any
  scrollTop: number
  positionType: any
  schoolObjects: any
}

export default defineComponent({
  name: 'PositionList',
  components: {
    PcBanner,
    // ZPositionFilter,
    ZPositionList,
    ZQrcode,
    ZPositionClone,
    ZSearch
  },
  props: {
    type: String
  },
  setup(props) {
    const { proxy }: { proxy: any } = getCurrentInstance() as ComponentInternalInstance
    const { type } = toRefs(props)
    const positionListRef = ref<null | HTMLElement>(null)
    const loading = proxy.$loading
    const route = useRoute()
    const swiperRef = ref(null)
    const qrCodeAreaRef = ref(null)
    let mouseX = ref(0)
    let mouseY = ref(0)

    const searchRef = ref(null)
    const positionType = ref(null)
    const recruitType = route.meta.recruitType

    const state = reactive({
      routeName: type.value,
      type: type.value,
      list: [],
      hasNextPage: true,
      pageNum: 1,
      search: '',
      positionTypeIds: [],
      workPlaceIds: [],
      scrollTop: 0,
      positionType: null,
      recruitType,
      schoolObjects: []
    }) as stateOption

    const isMyPage = ref(['myApply', 'myLike'].includes(state.routeName))
    /**
     * 离开前记录scrollTop
     */
    onBeforeRouteLeave((to, from, next) => {
      if (to.name == 'PositionDetail') {
        let scrollTop = document.getElementById('position').scrollTop
        state.scrollTop = scrollTop
        sessionStorage.setItem('positionKeepAlive', 'true')
      } else {
        sessionStorage.removeItem('positionKeepAlive')
      }
      next()
    })

    onActivated(() => {
      console.log(`onActivated`, sessionStorage.getItem('positionKeepAlive'))
      // true不需要刷新 false需要刷新
      if (!sessionStorage.getItem('positionKeepAlive')) {
        handlePageInit()
      } else {
        nextTick(() => {
          document.getElementById('position').scrollTo(0, state.scrollTop)
        })
      }
    })

    onMounted(() => {
      console.log(`onMounted`, sessionStorage.getItem('positionKeepAlive'))
      sessionStorage.removeItem('positionKeepAlive')
      qrCodeAreaRef.value.style.position = 'static'
    })

    const handleDragStart = (e) => {
      mouseX.value = e.pageX - qrCodeAreaRef.value.getBoundingClientRect().left
      mouseY.value = e.pageY - qrCodeAreaRef.value.getBoundingClientRect().top
    }

    const handleDragEnd = (e) => {
      qrCodeAreaRef.value.style.position = 'absolute'
      const disX = e.pageX - mouseX.value
      const disY = e.pageY - mouseY.value
      qrCodeAreaRef.value.style.top = disY + 'px'
      qrCodeAreaRef.value.style.left = disX + 'px'
    }

    /**
     * 页面初始化
     *
     */
    const handlePageInit = () => {
      console.log(`handlePageInit`)
      // 列表清空
      loading.show()
      state.list = []
      state.pageNum = 1
      state.hasNextPage = true

      // 职位类别选中重置
      state.positionTypeIds = null

      // 关键词搜素清空
      state.search = null

      // 城市选中清空
      state.workPlaceIds = null

      // 招聘对象选中清空
      state.schoolObjects = []

      /**
       * -------------以上为清空-------------
       * -------------以下为赋值-------------
       */

      // 选中对应职位类别(社招、校招)
      if (route.query.positionType) {
        positionType.value = route.query.positionType
        state.positionTypeIds = positionType.value.split('-')
      }

      // 选中对应招聘对象(校招)
      if (route.query.object) {
        state.schoolObjects = [route.query.object]
      }

      // 从首页搜索框或首页业务介绍进来
      if (route.query.search) {
        state.search = route.query.search
      }

      getPositionList()
    }

    const handleLoadingChange = () => {
      loading.hide()
    }

    const handleFilterChange = (positionTypeIds: any, workPlaceIds: any, schoolObjects: any) => {
      state.pageNum = 1
      state.list = []
      state.positionTypeIds = positionTypeIds
      state.workPlaceIds = workPlaceIds
      state.schoolObjects = schoolObjects
      getPositionList()
    }

    const handleInputChange = (search: string) => {
      state.search = search
      state.pageNum = 1
      state.list = []
      getPositionList()
    }

    const getPositionList = () => {
      if (!isMyPage.value) {
        const params = {
          queryString: state.search,
          queryPostNameString: null,
          schoolObject: state.schoolObjects,
          workPlaceIds: state.workPlaceIds,
          workPlaceFlag: state.workPlaceIds && state.workPlaceIds.includes(`0`) ? 1 : null,
          positionTypeIds: recruitType != 2 ? state.positionTypeIds : null,
          schoolPositionTypeIds: recruitType == 2 ? state.positionTypeIds : null,
          postType: recruitType,
          pageNum: state.pageNum,
          pageSize: 20
        }
        proxy.$http.position
          .getPostInfoPageList(params)
          .then((res: any) => {
            if (res && res.list) {
              state.hasNextPage = res.hasNextPage
              state.list = [...state.list, ...res.list]
            } else {
              state.hasNextPage = false
              state.list = []
            }
          })
          .finally(() => {
            loading.hide()
          })
      }

      if (state.type == 'myLike') {
        proxy.$http.position
          .getCollectPostInfoPageList({ pageNum: state.pageNum, pageSize: 20 })
          .then((res: any) => {
            if (res && res.list) {
              state.hasNextPage = res.hasNextPage
              state.list = [...state.list, ...res.list]
            } else {
              state.hasNextPage = false
              state.list = []
            }
          })
          .finally(() => {
            loading.hide()
          })
      }

      if (state.type == 'myApply') {
        const params = {
          pageNum: state.pageNum,
          pageSize: 20
        }
        proxy.$http.position
          .getMyApplication(params)
          .then((res: any) => {
            if (res && res.list) {
              state.hasNextPage = res.hasNextPage
              state.list = [...state.list, ...res.list]
            } else {
              state.hasNextPage = false
              state.list = []
            }
          })
          .finally(() => {
            loading.hide()
          })
      }
    }

    const handleLoad = () => {
      if (state.hasNextPage) {
        state.pageNum++
        getPositionList()
      }
    }

    return {
      ...toRefs(state),
      handleLoadingChange,
      handleFilterChange,
      handleInputChange,
      positionListRef,
      swiperRef,
      searchRef,
      qrCodeAreaRef,
      isMyPage,
      handleLoad,
      positionType,
      handlePageInit,
      handleDragStart,
      handleDragEnd
    }
  }
})
