/*
 * @Author: chengweijia-tel:18702108979
 * @Version: 1.0
 * @Date: 2022-02-14 09:18:43
 * @LastEditTime: 2022-03-02 15:04:31
 */
export const cities = [
  { workPlaceId: '310100', label: '上海' },
  { workPlaceId: '330100', label: '杭州' },
  { workPlaceId: '110100', label: '北京' },
  { workPlaceId: '440100', label: '广州' },
  { workPlaceId: '440300', label: '深圳' },
  { workPlaceId: '510100', label: '成都' },
  { workPlaceId: '500100', label: '重庆' },
  { workPlaceId: '420100', label: '武汉' },
  { workPlaceId: '0', label: '其他区域' }
]

export const CampusPositionType = [
  {
    value: '应届生',
    key: 'CAMPUS1',
    parent: '',
    list: [
      {
        parent: '',
        list: null,
        value: 'IT类',
        key: 'CAMPUS1-1'
      },
      {
        parent: '',
        list: null,
        value: '管培生',
        key: 'CAMPUS1-2'
      }
    ]
  },
  {
    parent: '',
    list: null,
    value: '实习生',
    key: 'CAMPUS2'
  }
]
export const jobCategory = [
  { name: '人事类', type: 1 },
  { name: '运营网管类', type: 2 },
  { name: '工程类', type: 3 },
  { name: '法务类', type: 4 },
  { name: '质量类', type: 5 },
  { name: 'IT类', type: 6 }
]
export const IT = {
  type: 6,
  arr: [
    '架构师',
    '软件工程师',
    '数据工程师',
    '产品经理',
    '质量工程师',
    '安全工程师',
    '运维工程师',
    '设计师',
    '技术支持工程师',
    '项目经理',
    '运营类',
    '职能类',
    '测试工程师',
    '大数据开发'
  ]
}
